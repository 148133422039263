angular.module("adminApp")
    .factory('showLicenseUsersAdminModal', ["$uibModal", function ($uibModal) {
        function open(license) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    license: function () {return license;}
            },
                templateUrl: "/static/templates/project/showLicenseUsersModal.html",
                controller: "ShowLicenseUsersAdminModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("ShowLicenseUsersAdminModalCtrl", ["$scope", "$uibModalInstance", "aerosAdminApi", "license", "Notification", function ($scope, $uibModalInstance, aerosAdminApi, license, Notification) {
        $scope.roleNameById = {};
        $scope.roleRankById = {};
        $scope.license = license;
        $scope.users = license.assignedToUsers;
        if(license.assignedToAdmin !== 'undefined') {
            $scope.users.push(license.assignedToAdmin);
        }

        $scope.unAssignUser = function (user) {
            aerosAdminApi.removeUserFromLicense(license.id, user.username)
                .success(function (data) {
                    var idxNewSerial = $scope.users.indexOf(user);
                    $scope.users.splice(idxNewSerial, 1);
                    Notification.success("User (" + user.username + ") successfully unassigned from licensekey (" + license.id + ")");
                })
                .error(function (err) {
                    Notification.error("User not unassigned. " + err.message);
                });
        };

        $scope.bestRankedId = function (roles) {
            var bestRank = 999;
            var bestRankedId = "";
            _.each(roles, function (role) {
                var roleLower = role.role.toLowerCase();
                var roleRank = $scope.roleRankById[role.role.toLowerCase()];
                if (roleRank < bestRank) {
                    bestRank = roleRank;
                    bestRankedId = roleLower;
                }
            });
            return bestRankedId;
        };
    }]);